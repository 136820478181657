import React from 'react'
import styled from 'styled-components'

import Wrapper from '../layouts/wrapper'
import Section from '../layouts/section'
import Button from '../layouts/button'

const Heading = styled.h2`
  margin-bottom: 24px;
  font-size: 1.5rem;
  font-weight: 800;

  @media (min-width: 768px) {
    font-size: 2.25rem;
  }
`

const CTAContent = styled.article`
  text-align: center;
`

const CallToAction = _ => {
  
  return (
    <Section bgSecondary paddedTop paddedBottom>
      <Wrapper>
        <CTAContent>
          <Heading>Your next project?<br />Contact us now.</Heading>
          <Button mod='primary' to='/contact/'>
            Get in touch
          </Button>
        </CTAContent>
      </Wrapper>
    </Section>
  )
}

export default CallToAction
