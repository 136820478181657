import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import { RightArrow } from '../svgs/icons'

const StyledLink = styled.span`
  text-decoration: none;
  font-size: 0;
  font-weight: 600;
  transition: opacity 0.25s ease-out;

  &:hover {
    opacity: 0.5;
  }

  > span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }

  > span {
    margin-right: 3px;
    font-size: 1rem;
  }

  ${props =>
    props.mod === 'primary' &&
    css`
      color: #45C2CA;

      > svg {
        fill: #45C2CA;
      }
    `
  }

  ${props =>
    props.mod === 'white' &&
    css`
      color: #FFF;

      > svg {
        fill: #FFF;
      }
    `
  }

${props =>
    props.mod === 'workCTA' &&
    css`
      color: #45C2CA;

      > svg {
        fill: #45C2CA;
      }

      @media (min-width: 1200px) {
        color: #FFF;

        > svg {
          fill: #FFF;
        }
      }
    `
  }
`

const IconLink = ({children, mod, to, as = Link}) => (
  <StyledLink as={as} mod={mod} to={to}>
    <span>
      {children}
    </span>
    <RightArrow />
  </StyledLink>
);

export default IconLink
