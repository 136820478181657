import React from 'react'
import { Link, graphql } from 'gatsby'

import styled from 'styled-components'

import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Section from '../layouts/section'
import Wrapper from '../layouts/wrapper'
import IconLink from '../layouts/IconLink'

import FormContact from '../components/form-contact'

import BgCurve from '../svgs/background-curve.svg'
import Illustration from '../svgs/contact-illustration.svg'

import CallToAction from '../components/call-to-action'

const PageSection = styled(Section)`
  position: relative;
  padding-top: 120px;
  padding-bottom: 48px;

  @media (min-width: 1024px) {
    padding-top: 200px;
    padding-bottom: 120px;
  }
`

const PageHeader = styled.header`
  margin-bottom: 48px;
  max-width: 600px;
`
const PageHeading = styled.h1`
  margin-bottom: 12px;
  font-family: 'Pacifico', serif;
  font-size: 3rem;
  font-weight: normal;
  line-height: 1;

  @media (min-width: 768px) {
    font-size: 3.75rem;
  }
`

const PageSubHeading = styled.p`
  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.5;
  }
`

const BgCurveWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;

  @media (min-width: 1024px) {
    display: block;
  }
`

const WorkList = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }

  @media (min-width: 1200px) {
    grid-gap: 2px;
  }
`
const WorkLink = styled(Link)`
  display: block;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 48px;

    @media (min-width: 1200px) {
      margin-bottom: 0;
    }
  }
`

const Work = styled.article`
  position: relative;
  overflow: hidden;
`

const WorkContent = styled.div`
  color: #4f4f4f;

  @media (min-width: 1200px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(-101%);
    padding: 48px;
    background-color: ${props => props.bgColor};
    color: #FFF;
    overflow: hidden;
    transition: transform 0.3s ease-out;

    ${Work}:hover > & {
      transform: none;
    }
  }
`

const WorkCoverImg = styled(Img)`
  margin-bottom: 24px;

  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
`

const WorkHeading = styled.h2`
  margin-bottom: 6px;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.25;

  @media (min-width: 1200px) {
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 1.2;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out 0.2s;

    ${Work}:hover & {
      transform: none;
    }
  }
`

const WorkClient = styled.p`
  margin-bottom: 12px;

  @media (min-width: 1200px) {
    transform: translateY(-300%);
    transition: transform 0.3s ease-out 0.2s;

    ${Work}:hover & {
      transform: none;
    }
  }
`

const WorkCTA = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    bottom: 48px;
    left: 48px;
    transform: translateY(500%);
    transition: transform 0.3s ease-out 0.2s;

    ${Work}:hover & {
      transform: none;
    }
  }
`

const WorksPage = ({ data, location }) => {

  //console.log(data.works)

  const workListing = data.works.edges.map(work => {

    const {
      title,
      client,
      brandColor,
      coverImg
    } = work.node.frontmatter

    return(
      <WorkLink key={title} to={work.node.fields.slug}>
        <Work>
        <WorkCoverImg fluid={coverImg.childImageSharp.fluid} />
          <WorkContent bgColor={brandColor}>
            <WorkHeading>
              {title}
            </WorkHeading>
            <WorkClient>
              {client}
            </WorkClient>
            <WorkCTA>
              <IconLink mod='workCTA' as='span'>
                View case study
              </IconLink>
            </WorkCTA>
          </WorkContent>
        </Work>
      </WorkLink>
    )
  })
  
  return (
    <Layout location={location.pathname}>
      <SEO title="Our recent web design, development and SEO portfolio | DesignWeb NZ" />
      <PageSection>
        <BgCurveWrapper>
          <BgCurve />
        </BgCurveWrapper>
        <Wrapper>
          <PageHeader>
            <PageHeading>Our work</PageHeading>
            <PageSubHeading>We design engaging brand communications and experiences, build websites, web maintenance and search engine optimisation.</PageSubHeading>
          </PageHeader>
          <WorkList>
            {workListing}
          </WorkList>
        </Wrapper>
      </PageSection>   
      <CallToAction />
    </Layout>
  )
}

export default WorksPage

export const query = graphql`
  query WorkpageQuery {
    works: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "\/work/"}
        frontmatter: {featured: {eq: true}}
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            client
            website
            brandColor
            coverImg {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;